import React, { useState, useEffect } from 'react'
import { BASE_API_URL } from '../global';
const axios = require('axios').default;

function Dashboard(props) {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [buildingData, setBuildingData] = useState(null);

    useEffect(() => {
        // api to get room status
        const API_URL = BASE_API_URL + '/getBuildingRoomData';
        axios.get(API_URL, { params: { buildingName: 'humanities_quadrangle' } }).then((res) => {
            setBuildingData(res.data);
            setLoading(false);
        }).catch((err) => {
            setError(err.message);
            setLoading(false);
        });
    }, []);

    if (loading) {
        return (
            <div>
                Loading...
            </div>
        )
    } else if (error) {
        return (
            <div>
                An error has occured!
            </div>
        )
    } else {
        return (
            <div>
                <p>{buildingData && buildingData.floor6_is_open ? 'Floor 6 is open! :)' : 'Floor 6 is currently occupied! :('}</p>
                <p>{buildingData && buildingData.floor7_is_open ? 'Floor 7 is open! :)' : 'Floor 7 is currently occupied! :('}</p>
                <p>{buildingData && buildingData.floor8_is_open ? 'Floor 8 is open! :)' : 'Floor 8 is currently occupied! :('}</p>
                <p>{buildingData && buildingData.floor9_is_open ? 'Floor 9 is open! :)' : 'Floor 9 is currently occupied! :('}</p>
                <p>{buildingData && buildingData.floor10_is_open ? 'Floor 10 is open! :)' : 'Floor 10 is currently occupied! :('}</p>
                <p>{buildingData && buildingData.floor11_is_open ? 'Floor 11 is open! :)' : 'Floor 11 is currently occupied! :('}</p>
                <p>{buildingData && buildingData.floor12_is_open ? 'Floor 12 is open! :)' : 'Floor 12 is currently occupied! :('}</p>
                <p>{buildingData && buildingData.floor13_is_open ? 'Floor 13 is open! :)' : 'Floor 13 is currently occupied! :('}</p>
            </div>
        )
    }

    
}

export default Dashboard
