import React, { useState, useEffect } from 'react'
import { BASE_API_URL } from '../global';
const axios = require('axios').default;

function Claim(props) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {

        const searchParams = new URLSearchParams(props.location.search);
        const buildingName = searchParams.get('buildingName');
        const roomName = searchParams.get('roomName');

        const API_URL = BASE_API_URL + '/claimRoom';
        axios.post(API_URL, { buildingName: buildingName, roomName: roomName }).then((res) => {
            setLoading(false);
        }).catch((err) => {
            setError(err.message);
            setLoading(false);
        });
    }, [])

    if (loading) {
        return (
            <div>
                Signing you into the room...
            </div>
        )
    } else if (error) {
        return (
            <div>
                An error has occured!
            </div>
        )
    } else {
        return (
            <div>
                Successfully claimed room!
            </div>
        )
    }
}

export default Claim
