
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Dashboard from './pages/Dashboard';
import Claim from './pages/Claim';
import Leave from './pages/Leave';

function App() {
  	return (
    	<Router>
			<Switch>
				<Route exact path='/' component={ Dashboard } />
				<Route path='/claim' component={ Claim } />
				<Route path='/leave' component={ Leave } />
			</Switch>
		</Router>
  	);
}

export default App;
